import memoize from 'lodash/memoize';

export const isHandheld = memoize(() => {
  if (typeof window === 'undefined') return false;

  return (
    window.navigator.userAgent.match(/Android/i) ||
    window.navigator.userAgent.match(/webOS/i) ||
    window.navigator.userAgent.match(/iPhone/i) ||
    window.navigator.userAgent.match(/iPod/i) ||
    window.navigator.userAgent.match(/iPad/i) ||
    window.navigator.userAgent.match(/BlackBerry/)
  );
});
