'use client';

import * as React from 'react';
import { TooltipControlled } from '@mentimeter/ragnar-ui';
import type { DefaultValidKeyCodesT } from '@mentimeter/hotkeys';
import { HotkeyHandler, HotkeyProvider } from '@mentimeter/hotkeys';
import {
  NEW_PRES_BUTTON_ID,
  NEW_FOLDER_BUTTON_ID,
} from 'features/dashboard/presentation-view/constants';

const HOTKEYS: DefaultValidKeyCodesT[] = [
  'escape', // GENERAL HOTKEY: Exits UI elements and cancles actions',
  'enter', // GENERAL HOTKEY: Confirms actions and submits',
  'p+ctrl', //`Opens the "New presentation" modal`,
  'f+ctrl', //`Opens the "New folder" modal`,
  'h', // Opens tooltip in dashboard item',
  'd+ctrl', // Deletes the hovered item in dashboard',
  'z+ctrl', // Undoes an action',
  'h+ctrl', // Shows all available hotkeys',
  's+ctrl', // Activates search mode',
  'x+ctrl', // Opens the move modal for a presentation',
];

export const DashboardHotkeyProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => <HotkeyProvider keyStrokes={HOTKEYS}>{children}</HotkeyProvider>;

interface HotkeyTooltip {
  description: string;
  referenceId: string;
}

const hotkeys: Array<HotkeyTooltip> = [
  {
    referenceId: NEW_PRES_BUTTON_ID,
    description: 'Ctrl+P',
  },
  {
    referenceId: NEW_FOLDER_BUTTON_ID,
    description: 'Ctrl+F',
  },
  {
    referenceId: 'delete-presentation-hotkey',
    description: 'To delete: Hover+Ctrl+D',
  },
  {
    referenceId: 'move-presentation-hotkey',
    description: 'To move: Hover+Ctrl+X',
  },
  {
    referenceId: 'search-hotkey',
    description: 'Ctrl+S',
  },
];

const SHOW_ALL_HOTKEYS_KEY_CODE = 'h+ctrl';

export const HotkeyLayer = () => {
  const [isPressing, setIsPressing] = React.useState(false);
  return (
    <HotkeyHandler
      keyCode={SHOW_ALL_HOTKEYS_KEY_CODE}
      onKeyUp={() => {
        setIsPressing(false);
      }}
      onKeyPress={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !isPressing && setIsPressing(true);
      }}
    >
      {isPressing &&
        hotkeys.map<React.ReactNode>((d) => {
          const ref = document.getElementById(d.referenceId);
          if (ref) {
            return (
              <TooltipControlled
                key={d.referenceId}
                {...d}
                show={Boolean(ref && isPressing)}
              />
            );
          }
          return null;
        })}
    </HotkeyHandler>
  );
};
