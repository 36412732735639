'use client';

import { isHandheld } from '@mentimeter/__app-split-lib/device';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider as ReactDndProvider } from 'react-dnd';

export const DndProvider = ({ children }: { children: React.ReactNode }) => {
  const DnDBackend = isHandheld() ? TouchBackend : HTML5Backend;

  return <ReactDndProvider backend={DnDBackend}>{children}</ReactDndProvider>;
};
